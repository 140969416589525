<account-modal
  [heading]="'LICENSES.LICENSE_KEYS.LICENSE_KEY_MODAL.HEADING' | translate"
  [focusInput]="false"
  (modalClose)="closeModal()"
  maxWidth="600px">
  <modal-body>
    <textarea class="form-control is_disabled" [value]="key" rows="6" readonly></textarea>
    <br />
    <button [ngClass]="['a_button', 'is_small', highlighted ? 'is_highlighted' : '']" (click)="copyToClipboard()">
      {{ 'LICENSES.LICENSE_KEYS.LICENSE_KEY_MODAL.ACTION.COPY' | translate }}
    </button>
  </modal-body>
  <modal-footer>
    <button class="a_button is_small" (click)="closeModal()">
      {{ 'COMMON.CLOSE' | translate }}
    </button>
  </modal-footer>
</account-modal>
