import { Workspace, WorkspaceItem } from '../../models/app/navigation.model';

export const workspaces: Workspace[] = [
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER_MANAGEMENT',
    name: 'partner',
    icon: 'briefcase',
    color: 'var(--sw-module-green-500)',
    path: 'partner',
    hasChangelog: true,
  },
  {
    label: 'NAVIGATION.WORKSPACE.PLUGIN_MANAGEMENT',
    name: 'producer',
    icon: 'plug',
    color: 'var(--sw-module-blue-500)',
    path: 'producer',
    hasChangelog: true,
  },
  {
    label: 'NAVIGATION.WORKSPACE.SHOP_MANAGEMENT',
    name: 'shopOwner',
    icon: 'shopping-basket',
    color: 'var(--sw-module-yellow-500)',
    path: 'shops',
    hasChangelog: true,
  },
  {
    label: 'NAVIGATION.WORKSPACE.ACADEMY_MANAGEMENT',
    name: 'academy',
    icon: 'graduation-cap',
    color: 'var(--sw-module-purple-500)',
    path: 'academy',
  },
  {
    label: 'NAVIGATION.WORKSPACE.ACCOUNT_MANAGEMENT',
    name: 'company',
    icon: 'home',
    color: 'var(--sw-gray-700)',
    path: 'profile',
  },
  {
    label: 'NAVIGATION.WORKSPACE.USER_SETTINGS',
    name: 'userAccount',
    icon: 'cog',
    color: 'var(--sw-gray-700)',
    path: 'settings',
  },
  {
    label: 'NAVIGATION.WORKSPACE.COMPANY_REGISTRATION',
    name: 'companyRegistration',
    icon: 'briefcase',
    color: 'var(--sw-gray-700)',
    path: 'company',
  },
];

/* ################################ */

export const partnerWorkspace: WorkspaceItem[] = [
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.WELCOME',
    icon: 'dashboard',
    path: 'partner/welcome',
    alwaysVisible: true,
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.DEALS',
    icon: 'check-square',
    path: 'partner/deals',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.CUSTOMERS',
    icon: 'users',
    path: 'partner/customers',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.REFERENCES',
    icon: 'window',
    path: 'partner/references',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.PROFILE',
    icon: 'user',
    path: 'partner/profile',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.TECHNOLOGY_PROFILE',
    icon: 'books',
    path: 'partner/technologyprofile',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.SHOP.ACCOUNT',
    icon: 'credit-card',
    path: 'partner/account',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.SUPPORT',
    icon: 'comments',
    path: 'partner/support',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.COMMISSIONS',
    icon: 'wallet',
    path: 'partner/commissions',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.WILDCARD_ENVIRONMENTS',
    icon: 'server',
    path: 'partner/wildcardenvironments',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.MARKETING_MATERIAL',
    icon: 'megaphone',
    path: 'partner/marketing',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.POINTS',
    icon: 'trophy',
    path: 'partner/points',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.BUDGET',
    icon: 'money-bill',
    path: 'partner/budget',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.DOCUMENTATION',
    icon: 'file-text',
    path: 'partner/documentation',
    externalLink: 'NAVIGATION.WORKSPACE.PARTNER.DOCUMENTATION_LINK',
    alwaysVisible: true,
  },
];

/* ################################ */

export const producerWorkspace: WorkspaceItem[] = [
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.WELCOME',
    icon: 'dashboard',
    path: 'producer/welcome',
    alwaysVisible: true,
  },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.EXTENSIONS',
    icon: 'bars-square',
    path: 'producer/plugins',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.DISCOUNT_CAMPAIGNS',
    icon: 'money-bill',
    path: 'producer/discounts',
    // badge: true,
  },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.SUPPORT',
    icon: 'comments',
    path: 'producer/support',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.REVIEWS',
    icon: 'star',
    path: 'producer/reviews',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.REVENUES',
    icon: 'wallet',
    path: 'producer/revenues',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.SALES',
    icon: 'shopping-basket',
    path: 'producer/sales',
  },
  // {
  //   label: 'Auswertungen',
  //   icon: 'chart-pie',
  //   path: 'producer/analytics',
  // },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.PRODUCER_PROFILE',
    icon: 'user',
    path: 'producer/profile',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PARTNER.WILDCARD_ENVIRONMENTS',
    icon: 'server',
    path: 'producer/wildcardenvironments',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.MARKETING_MATERIAL',
    icon: 'megaphone',
    path: 'producer/marketing',
  },
  {
    label: 'NAVIGATION.WORKSPACE.PRODUCER.DOCUMENTATION',
    icon: 'file-text',
    path: 'producer/documentation',
    externalLink: 'NAVIGATION.WORKSPACE.PRODUCER.DOCUMENTATION_LINK',
    alwaysVisible: true,
  },
];

/* ################################ */

export const shopOwnerWorkspace: WorkspaceItem[] = [
  {
    label: 'NAVIGATION.WORKSPACE.SHOPS.WELCOME',
    icon: 'dashboard',
    path: 'shops/welcome',
  },
  {
    label: 'NAVIGATION.WORKSPACE.SHOPS.SHOPS',
    icon: 'marketplace-stall',
    path: 'shops/shops',
  },
  {
    label: 'NAVIGATION.WORKSPACE.SHOPS.SUPPORT',
    icon: 'comments',
    path: 'shops/support',
  },
  {
    label: 'NAVIGATION.WORKSPACE.SHOPS.DOCUMENTATION',
    icon: 'file-text',
    path: 'shops/documentation',
    externalLink: 'NAVIGATION.WORKSPACE.SHOPS.DOCUMENTATION_LINK',
  },
  {
    label: 'NAVIGATION.WORKSPACE.SHOPS.FAQ',
    icon: 'comments',
    path: '',
    externalLink: 'NAVIGATION.WORKSPACE.SHOPS.FAQ_LINK',
    alwaysVisible: true,
  },
];

/* ################################ */

export const academyWorkspace: WorkspaceItem[] = [
  {
    label: 'NAVIGATION.WORKSPACE.ACADEMY.WELCOME',
    icon: 'dashboard',
    path: 'academy/welcome',
  },
  {
    label: 'NAVIGATION.WORKSPACE.ACADEMY.CERTIFICATIONS',
    icon: 'file-signature',
    path: 'academy/certifications',
  },
  {
    label: 'NAVIGATION.WORKSPACE.ACADEMY.BOOKINGS',
    icon: 'calendar',
    path: 'academy/bookings',
  },
  {
    label: 'NAVIGATION.WORKSPACE.ACADEMY.EXAMINATIONS',
    icon: 'calendar',
    path: 'academy/examinations',
  },
  // {
  //   label: 'NAVIGATION.WORKSPACE.ACADEMY.DOCUMENTATION',
  //   icon: 'file-text',
  //   path: 'academy/documentation',
  //   externalLink: 'NAVIGATION.WORKSPACE.ACADEMY.DOCUMENTATION_LINK',
  // }
];

/* ################################ */

export const companyWorkspace: WorkspaceItem[] = [
  {
    label: 'NAVIGATION.WORKSPACE.ACCOUNT.MASTER_DATA',
    icon: 'briefcase',
    path: 'profile/data',
  },
  {
    label: 'NAVIGATION.WORKSPACE.ACCOUNT.BILLING',
    icon: 'wallet',
    path: 'profile/billing',
  },
  {
    label: 'NAVIGATION.WORKSPACE.ACCOUNT.MEMBERSHIP',
    icon: 'users',
    path: 'profile/memberships',
  },
  {
    label: 'NAVIGATION.WORKSPACE.MY_PARTNER',
    icon: 'briefcase',
    path: 'profile/my-partner',
    featureFlag: 'partner-customer-relation',
  },
  {
    label: 'NAVIGATION.WORKSPACE.ACCOUNT.DOCUMENTATION',
    icon: 'file-text',
    path: 'profile/documentation',
    externalLink: 'NAVIGATION.WORKSPACE.ACCOUNT.DOCUMENTATION_LINK',
  }
];

/* ################################ */

export const userAccountWorkspace: WorkspaceItem[] = [
  {
    label: 'NAVIGATION.WORKSPACE.USER_INFORMATION',
    icon: 'user',
    path: 'settings/myaccount',
    whenFeatureFlagDeactivated: 'ory',
  },
  {
    label: 'NAVIGATION.WORKSPACE.USER_INFORMATION',
    icon: 'user',
    path: '/settings/externalmyaccount',
    // gets overwritten based on environment in Components/Account2/src/app/core/store/navigation/navigation.effects.ts:43
    externalLink: 'https://auth.shopware.com/settings',
    featureFlag: 'ory',
  },
  {
    label: 'NAVIGATION.WORKSPACE.USER_MEMBERSHIPS',
    icon: 'file-signature',
    path: 'settings/companymemberships',
  },
];

export const companyRegistrationWorkspace: WorkspaceItem[] = [
  {
    label: 'NAVIGATION.WORKSPACE.COMPANY_REGISTRATION',
    icon: 'briefcase',
    path: 'company/registration',
    alwaysVisible: true,
  }
]
