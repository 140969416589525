import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver-es';

@Injectable({
  providedIn: 'root',
})
export class FileSaveService {
  saveAs(file: Blob, name: string): void {
    FileSaver.saveAs(file, name);
  }
}
