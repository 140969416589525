<form *ngIf="form" [formGroup]="form">
  <account-modal paddingBody="padding_none" [heading]="heading" (modalClose)="closeModal()">
    <modal-body>
      <div class="a_row">
        <div class="a_column-12 a_column-l-6" data-border-right="l,x">
          <div>
            <div
              class="a_control is_required"
              [class.is_error]="
                (form.get('accountHolder').dirty || form.get('accountHolder').touched) &&
                form.get('accountHolder').errors
              ">
              <div class="control_description">
                <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.ACCOUNT_HOLDER' | translate }}</strong>
              </div>
              <div class="control_content">
                <input type="text" formControlName="accountHolder" />
              </div>
            </div>
            <div
              class="a_control is_required"
              [class.is_error]="
                (form.get('bankName').dirty || form.get('bankName').touched) && form.get('bankName').errors
              ">
              <div class="control_description">
                <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.BANK_NAME' | translate }}</strong>
              </div>
              <div class="control_content">
                <input type="text" formControlName="bankName" />
              </div>
            </div>
            <div
              class="a_control is_required"
              [class.is_error]="(form.get('iban').dirty || form.get('iban').touched) && form.get('iban').errors">
              <div class="control_description">
                <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.BANK_DETAILS' | translate }}</strong>
              </div>
              <toggle-button class="toggle" formControlName="isIban">
                {{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.' + bankData.context | uppercase | translate }}
              </toggle-button>
              <div class="control_content">
                <input type="text" formControlName="iban" />
              </div>
              <div class="control_help">
                <span *ngIf="(form.get('iban').dirty || form.get('iban').touched) && form.get('iban').errors">
                  {{
                    form.get('isIban').value
                      ? ('ACCOUNTING.BANK_ACCOUNT.MODAL.FORM.ERROR_TEXT.IBAN' | translate: { min: 15, max: 32 })
                      : ('ACCOUNTING.BANK_ACCOUNT.MODAL.FORM.ERROR_TEXT.INVALID_FORMAT' | translate)
                  }}
                </span>
              </div>
            </div>
            <div
              class="a_control is_required"
              [class.is_error]="(form.get('bic').dirty || form.get('bic').touched) && form.get('bic').errors">
              <div class="control_description">
                <strong>{{ 'ACCOUNTING.BANK_ACCOUNT.LABEL.BIC' | translate }}</strong>
              </div>
              <div class="control_content">
                <input type="text" formControlName="bic" />
              </div>
              <div class="control_help">
                <span *ngIf="(form.get('bic').dirty || form.get('bic').touched) && form.get('bic').errors">
                  {{ 'ACCOUNTING.BANK_ACCOUNT.MODAL.FORM.ERROR_TEXT.BIC' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="a_column-12 a_column-l-6 info-text">
          <h3>{{ 'ACCOUNTING.BANK_ACCOUNT.MODAL.INFO.HEADLINE' | translate }}</h3>
          <p *ngIf="textAsHTML" [innerHTML]="infoText"></p>
          <p *ngIf="!textAsHTML">{{ infoText }}</p>
        </div>
      </div>
    </modal-body>
    <modal-footer>
      <button
        type="button"
        (click)="save()"
        variant="primary"
        class="a_button is_primary is_small"
        [disabled]="form.invalid || !hasPendingChanges()"
        [class.is_disabled]="form.invalid || !hasPendingChanges()">
        {{ 'COMMON.SAVE' | translate }}
      </button>
    </modal-footer>
  </account-modal>
</form>
