import { UserAccountsFacade } from '@account/core/facades';
import { CreateCompanyWithBillingCountry, SbpException } from '@account/core/models';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '../components/modal/modal.service';
import { ToastService } from '../components/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class CreateCompanyService {
  constructor(
    private readonly modalService: ModalService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly userAccountsFacade: UserAccountsFacade
  ) {}

  createCompany(company: CreateCompanyWithBillingCountry): void {
    this.userAccountsFacade.createCompany(company, false).subscribe({
      next: () => {
        this.toastService.success(
          this.translateService.instant('COMPANY.CREATION.TOAST.SUCCESS.TITLE.COMPANY_CREATED'),
          this.translateService.instant('COMPANY.CREATION.TOAST.SUCCESS.MESSAGE.COMPANY_CREATED', {
            companyName: company.name,
          })
        );
        this.router.navigate(['/changecompany']);
      },
      error: (error: SbpException) => {
        switch (error.code) {
          case 'ApiRequestException-5':
            break;
          case 'AddressException-1':
            this.toastService.error(
              this.translateService.instant('COMPANY.CREATION.TOAST.ERROR.TITLE.COMPANY_CREATION_FAILED'),
              this.translateService.instant('COMPANY.CREATION.TOAST.ERROR.MESSAGE.ADDRESS_VALIDATION_FAILED.MESSAGE')
            );
            break;
          default:
            this.toastService.error(
              this.translateService.instant('COMPANY.CREATION.TOAST.ERROR.TITLE.COMPANY_CREATION_FAILED'),
              this.translateService.instant('COMPANY.CREATION.TOAST.ERROR.MESSAGE.COMPANY_CREATION_FAILED')
            );
        }
      },
    });
  }
}
