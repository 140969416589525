/**
 * @link https://github.com/robisim74/angular-l10n/blob/master/src/pipes/locale-number.pipe.ts
 */
import { LanguagesFacade } from '@account/core/facades';
import { Language, valueIsMoney } from '@account/core/models';
import { LocalizationService } from '@account/shared/services';
import { DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'localeDecimal',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class LocaleDecimalPipe implements PipeTransform {
  private readonly currentLanguage$: Observable<Language>;

  constructor(private readonly languagesFacade: LanguagesFacade) {
    this.currentLanguage$ = this.languagesFacade.getSelectedLanguage();
  }

  transform(value: any, digits: string = null): string {
    let langKey = '';
    this.currentLanguage$.pipe(take(1)).subscribe((language: Language) => (langKey = language.key));

    const localeDecimal: DecimalPipe = new DecimalPipe(langKey);
    return localeDecimal.transform(value, digits);
  }
}

@Pipe({
  name: 'localePercent',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class LocalePercentPipe implements PipeTransform {
  private readonly currentLanguage$: Observable<Language>;

  constructor(private readonly languagesFacade: LanguagesFacade) {
    this.currentLanguage$ = this.languagesFacade.getSelectedLanguage();
  }

  transform(value: any, digits: string = null): string {
    let langKey = '';
    this.currentLanguage$.pipe(take(1)).subscribe((language: Language) => (langKey = language.key));

    const localePercent: PercentPipe = new PercentPipe(langKey);
    return localePercent.transform(value, digits);
  }
}

@Pipe({
  name: 'localeCurrency',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class LocaleCurrencyPipe implements PipeTransform {
  constructor(private readonly localizationsService: LocalizationService) {}

  transform(
    amount: any,
    currency = '',
    symbolDisplay: boolean | 'symbol' | 'code' | 'symbol-narrow' = 'symbol',
    digits: string = null
  ): string {
    if (amount && valueIsMoney(amount)) {
      currency = amount.currency;
      amount = amount.value;
    }

    return this.localizationsService.getLocaleCurrency(amount, currency, symbolDisplay, digits);
  }
}
