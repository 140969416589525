import { CommercialContract, PluginLicense, ProductLicense } from '@account/core/models';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PluginLicensesService {
  constructor(private readonly translateService: TranslateService) {}

  isRentLicenseInTrialPhase(license: PluginLicense): boolean {
    return (
      'rent' === license.variantType.name &&
      license.trialPhaseIncluded &&
      license.charging &&
      license.creationDate === license.charging.lastBookingDate &&
      license.salesChannel !== 'pluginAbuseCharging'
    );
  }

  isRentLicenseWithDiscount(license: PluginLicense): boolean {
    return (
      'rent' === license.variantType.name &&
      license.originalRentPrice &&
      license.price < license.originalRentPrice &&
      license.charging &&
      license.charging.lastBookingDate <= license.charging.discountedUntil
    );
  }

  isPluginOfferingSubscription(license: PluginLicense): boolean {
    return (
      'isSubscriptionEnabled' in license.plugin &&
      license.plugin['isSubscriptionEnabled'] &&
      !license.plugin.isAdvancedFeature &&
      !license.plugin.isSW6EnterpriseFeature &&
      !license.plugin.isSW6ProfessionalEditionFeature
    );
  }

  isPluginOfferingRentVariant(license: PluginLicense): boolean {
    const plugin = license.plugin;
    return plugin.variants.find((variant) => variant.priceModel.type === 'rent') !== undefined;
  }

  getPurchaseType(
    license: PluginLicense,
    productLicense?: ProductLicense,
    commercialContract?: CommercialContract
  ): string {
    if (license.plugin) {
      if (
        productLicense &&
        (license.plugin.isPremiumPlugin ||
          license.plugin.isSW6ProfessionalEditionFeature ||
          license.plugin.isSW6EnterpriseFeature ||
          license.plugin.isAdvancedFeature)
      ) {
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.INCLUDED_IN_PRODUCT_LICENSE');
      }

      if (license.plugin.isPremiumPlugin) {
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.BUY');
      }

      if (
        commercialContract &&
        !!license.plugin.addons.find((addon) => addon.name === commercialContract.bookedCommercialPlan.name)
      ) {
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.INCLUDED_IN_PRODUCT_LICENSE');
      }
    }

    switch (license.variantType.name) {
      case 'rent':
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.RENT');
      case 'free':
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.FREE');
      case 'support':
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.SUPPORT');
      case 'test':
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.TEST');
      case 'producerLicensed':
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.PRODUCER_LICENSED');
      default:
        return this.translateService.instant('COMMON.SHOP.LICENSE_TRANSFER.TYPE.BUY');
    }
  }
}
