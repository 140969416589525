import { NavigationFacade, UserAccountsFacade } from '@account/core/facades';
import {CompanyOnboardingData} from '@account/core/models';
import {ToastService} from '@account/shared/components';
import { StringValidators } from '@account/shared/validators';
import {inject, Injectable} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';


export interface CompanyOnboardingForm {
  companyName: FormControl<string>;
  countryId: FormControl<number | null>;
  locale: FormControl<string>;
  gtc: FormControl<boolean>;
  smallTradesman: FormControl<boolean>;
  email: FormControl<string>;
  salutation: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  phone: FormControl<string>;
  street: FormControl<string>;
  city: FormControl<string>;
  zipCode: FormControl<string>;
  isHavingVatId: FormControl<boolean>;
  vatId: FormControl<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyOnboardingService {

  private readonly formBuilder = inject(FormBuilder);
  private readonly userAccountFacade = inject(UserAccountsFacade);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  private readonly navigationFacade = inject(NavigationFacade);
  private readonly router = inject(Router);

  createNewCompanyDuringOnboarding(companyOnboardingData: CompanyOnboardingData): void {
    this.userAccountFacade.createCompanyDuringOnboarding(companyOnboardingData)
      .subscribe({
        next: () => {
          this.toastService.success(
              this.translateService.instant('COMPANY.ONBOARDING.TOAST.SUCCESS_TITLE'),
              this.translateService.instant('COMPANY.ONBOARDING.TOAST.SUCCESS_MESSAGE')
          );

          this.navigationFacade.selectWorkspace(null);
          this.router.navigate(['company', 'registration', 'success']);
        },
        error: () => {
          this.toastService.error(
              this.translateService.instant('COMPANY.ONBOARDING.TOAST.ERROR_TITLE'),
              this.translateService.instant('COMPANY.ONBOARDING.TOAST.ERROR_MESSAGE')
          );
        }
    });
  }

  createCompanyOnboardingForm(): FormGroup<CompanyOnboardingForm> {
    const form = this.formBuilder.group<CompanyOnboardingForm>({
      companyName: this.formBuilder.control<string>('', [Validators.required]),
      countryId: this.formBuilder.control<number | null>(null, [Validators.required]),
      locale: this.formBuilder.control<string>('', [Validators.required]),
      gtc: this.formBuilder.control<boolean>(false, [Validators.requiredTrue]),
      smallTradesman: this.formBuilder.control<boolean>(false),
      email: this.formBuilder.control<string>('', [Validators.required, StringValidators.email]),
      salutation: this.formBuilder.control<string>('', [Validators.required]),
      firstName: this.formBuilder.control<string>('', [Validators.required]),
      lastName: this.formBuilder.control<string>('', [Validators.required]),
      phone: this.formBuilder.control<string>('', [Validators.required, StringValidators.phone]),
      street: this.formBuilder.control<string>('', [Validators.required, Validators.minLength(3)]),
      city: this.formBuilder.control<string>('', [Validators.required, Validators.minLength(3)]),
      zipCode: this.formBuilder.control<string>('', [Validators.required]),
      isHavingVatId: this.formBuilder.control<boolean>(false),
      vatId: this.formBuilder.control<string | null>({value: null, disabled: true}),
    }, {validators: this.validateBusinessType()});

    const isSmallBusinessOwnerControl = form.get('smallTradesman');
    const isHavingVatIdControl = form.get('isHavingVatId');
    const vatIdControl = form.get('vatId');

    combineLatest([
      isSmallBusinessOwnerControl!.valueChanges.pipe(startWith(isSmallBusinessOwnerControl!.value)),
      isHavingVatIdControl!.valueChanges.pipe(startWith(isHavingVatIdControl!.value))
    ]).subscribe(([isSmallBusinessOwner, isHavingVatId]) => {
      if (isSmallBusinessOwner) {
        isHavingVatIdControl!.disable({ emitEvent: false });
        vatIdControl!.disable({ emitEvent: false });
        vatIdControl!.reset();
      } else {
        isHavingVatIdControl!.enable({ emitEvent: false });
        if (isHavingVatId) {
          vatIdControl!.enable({ emitEvent: false });
        } else {
          vatIdControl!.disable({ emitEvent: false });
          vatIdControl!.reset();
        }
      }

      if (isHavingVatId) {
        isSmallBusinessOwnerControl!.disable({ emitEvent: false });
      } else {
        isSmallBusinessOwnerControl!.enable({ emitEvent: false });
      }
    });

    return form;
  }

  private validateBusinessType(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isSmallBusinessOwner = control.get('smallTradesman')?.value;
      const isHavingVatId = control.get('isHavingVatId')?.value;
      const vatId = control.get('vatId')?.value;

      if(isSmallBusinessOwner && isHavingVatId) {
        return {vatIdError: 'You cannot be a small business owner and have a VAT ID at the same time.'};
      }

      if (isHavingVatId && !vatId) {
        return {vatIdError: 'The VAT ID must be filled if the company is no small business owner.'};
      }

      return null;
    };
  }
}
