import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompanyOnboardingData, HttpParamsBuilder } from '../../../core';
import {
  Company,
  CompanyMembership,
  CompanyMembershipInvitation,
  CreateCompanyWithBillingCountry,
  ListResult,
  PasswordChange,
  PasswordResetChange,
  PasswordResetRequest,
  S3Download,
  TokenResult,
  UserAccount,
  UserAccountRegistration,
  Verification,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { UserAccessLog } from '../../models/user/user-access-log.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class UserAccountsGateway {
  constructor(private readonly apiService: ApiService) {}

  getMe(): Observable<UserAccount> {
    return this.apiService.get('/account/me');
  }

  getUserAccount(id: number): Observable<UserAccount> {
    return this.apiService.get(`/account/${id}`);
  }

  createUserAccount(userAccountRegistrationData: UserAccountRegistration): Observable<UserAccount> {
    return this.apiService.post('/account', userAccountRegistrationData);
  }

  verify(verification: Verification): Observable<UserAccount> {
    return this.apiService.post('/account/verify', verification);
  }

  updateUserAccount(userAccount: UserAccount): Observable<UserAccount> {
    return this.apiService.put(`/account/${userAccount.id}`, userAccount);
  }

  getMemberships(id: number): Observable<CompanyMembership[]> {
    return this.apiService.get(`/account/${id}/memberships`);
  }

  changeSelectedMembership(id: number, membershipId: number): Observable<UserAccount> {
    const data = {
      membership: { id: membershipId },
    };
    return this.apiService.post(`/account/${id}/memberships/change`, data);
  }

  terminateMembership(userAccountId: number, membershipId: number): Observable<void> {
    return this.apiService.delete(`/account/${userAccountId}/memberships/${membershipId}`);
  }

  getMembershipInvitations(id: number): Observable<CompanyMembershipInvitation[]> {
    return this.apiService.get(`/account/${id}/invitations`);
  }

  createCompany(id: number, company: CreateCompanyWithBillingCountry, approveGtc: boolean): Observable<Company> {
    const data = {
      company: company,
      approveGtc: approveGtc,
    };
    return this.apiService.post(`/account/${id}/companies`, data);
  }

  createCompanyDuringOnboarding(companyOnboardingData: CompanyOnboardingData): Observable<Company> {
    return this.apiService.post('/companies/register', companyOnboardingData);
  }

  changePassword(id: number, payload: PasswordChange): Observable<TokenResult> {
    return this.apiService.put(`/account/${id}/password/change`, payload);
  }

  uploadAvatar(id: number, file: File): Observable<S3Download> {
    return this.apiService.upload(`/account/${id}/uploadAvatar`, file);
  }

  getAvatar(id: number): Observable<S3Download> {
    return this.apiService.get(`/account/${id}/avatar`);
  }

  requestPasswordReset(passwordResetRequest: PasswordResetRequest): Observable<void> {
    return this.apiService.post('/auth/password/reset', passwordResetRequest);
  }

  passwordResetVerify(token: string): Observable<void> {
    return this.apiService.get(`/password/reset/verify/${token}`);
  }

  passwordReset(token: string, newPasswords: PasswordResetChange): Observable<void> {
    return this.apiService.put(`/password/reset/${token}`, newPasswords);
  }

  getAccessLogs(id: number, metaData: RequestMetaData): Observable<ListResult<UserAccessLog[]>> {
    const params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    return this.apiService.getList(`/account/${id}/accesslogs`, params);
  }
}
