import { PluginLicense, RentLicenseUpgradeOption } from '@account/core/models';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { isDateExpired } from '../util';

@Injectable({
  providedIn: 'root',
})
export class RentLicenseUpgradeService {
  constructor(private readonly translateService: TranslateService) {}

  getDiscountForRentUpgradeOption(rentUpgradeOptions: RentLicenseUpgradeOption[], duration: number): number {
    if (duration === 1) {
      return 50;
    }

    return this.calculateDiscountForAnnualRentOption(rentUpgradeOptions);
  }

  getDiscountBadgeText(rentUpgradeOptions: RentLicenseUpgradeOption[], duration: number): string {
    const totalDiscount = this.getDiscountForRentUpgradeOption(rentUpgradeOptions, duration);
    const discountString = this.translateService.instant('COMMON.DISCOUNT');

    return `${totalDiscount}% ${discountString}`;
  }

  getDiscountBadgeColor(rentUpgradeOptions: RentLicenseUpgradeOption[], duration: number): string {
    const totalDiscount = this.getDiscountForRentUpgradeOption(rentUpgradeOptions, duration);
    if (totalDiscount > 50) {
      return 'error';
    }

    return 'default';
  }

  getRentUpgradeInfoText(license: PluginLicense, maxDiscountForRentUpgradeOptions: number): string {
    let currentSubscriptionType = '';
    if (isDateExpired(license.subscription?.expirationDate)) {
      currentSubscriptionType = 'EXPIRED_SUBSCRIPTION';
    } else {
      currentSubscriptionType = 'ACTIVE_SUBSCRIPTION';
    }

    let additionalDiscountHint = '';
    if (maxDiscountForRentUpgradeOptions > 50) {
      additionalDiscountHint = this.translateService.instant('COMMON.UP_TO');
    }

    return this.translateService.instant(
      `SHOP_OWNER.LICENSE_DETAILS.UPGRADE_TO_RENT.INFO_TEXT.${currentSubscriptionType}`,
      {
        maxDiscount: maxDiscountForRentUpgradeOptions,
        additionalDiscountHint: additionalDiscountHint,
      }
    );
  }

  private calculateDiscountForAnnualRentOption(rentUpgradeOptions: RentLicenseUpgradeOption[]): number {
    const monthlyRentUpgradeOption = rentUpgradeOptions.find(
      (option: RentLicenseUpgradeOption) => option.duration === 1
    );
    const annualRentUpgradeOption = rentUpgradeOptions.find(
      (option: RentLicenseUpgradeOption) => option.duration === 12
    );

    if (annualRentUpgradeOption === undefined) {
      return 0;
    }

    const monthlyRentForOneYear = monthlyRentUpgradeOption.originalPrice * 12;
    const additionalDiscount = ((1 - annualRentUpgradeOption.price / monthlyRentForOneYear) * 100 * 100) / 100;
    return Math.round(additionalDiscount * 100) / 100;
  }
}
