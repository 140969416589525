<ng-container *ngIf="activeUserContext$ | async as activeUserContext">
  <ng-template></ng-template>

  <div class="navigation--button" (click)="toggleNavigation()">
    <account-sw-icon icon="times-s" size="24px"></account-sw-icon>
  </div>

  <div
    class="info-content"
    (click)="goToPortal()"
    [class.has--single-company]="!activeUserContext.hasMultipleCompanies">
    <div class="info--icon"></div>
  </div>

  @if (activeUserContext.company) {
    <div class="info-company" (click)="toggle()" [class.has--single-company]="!activeUserContext.hasMultipleCompanies">
      <div class="company-content">
        <span class="content-name">{{ activeUserContext.company.name }}</span>
        <small *ngIf="activeUserContext.company.masterData.customerNumber">
          {{ 'PROFILE.MASTER_DATA.ACCOUNT_INFORMATION.CUSTOMER_ID' | translate }} :
          {{ activeUserContext.company.masterData.customerNumber }}
        </small>
      </div>
      <account-sw-icon
        *ngIf="activeUserContext.hasMultipleCompanies"
        icon="chevron-down-s"
        size="10px"></account-sw-icon>
    </div>

    <div class="content" [class.is--active]="activeUserContext.hasMultipleCompanies && open">
      <ng-container *ngFor="let membership of activeUserContext.memberships">
        <a
          *ngIf="membership.company.id !== activeUserContext.company.id"
          class="content--entry"
          (click)="changeSelectedMembership(membership)">
          <account-overflow-tooltip tooltipType="text" [content]="membership.company.name"></account-overflow-tooltip>
        </a>
      </ng-container>
    </div>
  }
</ng-container>
